import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 4; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/06-feb-24-2/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function SafeUnsafeTouch2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Safe - Unsafe Touch</h1>

        <p>
          Chinmaya Vidyalaya recently conducted an informative session on
          Safe-Unsafe Touch for the students of the Pre-Primary section.
          <br />
          <br />
          A dedicated special educator led an interactive session to sensitively
          introduce the concepts of 'Good touch' and 'Bad touch.'
          <br />
          <br />
          The objective was to provide the children with the knowledge and
          understanding to differentiate between positive and inappropriate
          physical contact.
          <br />
          <br />
          Learners engaged in a series of activities involving giving thumbs up
          or thumbs down for various situations which made the session engaging
          and helped the children grasp the concepts in a practical way.
          <br />
          <br />
          A creative activity was also conducted where learners coloured a
          worksheet highlighting the private parts of their bodies. It served as
          a visual aid, reinforcing the importance of recognising and respecting
          personal boundaries.
          <br />
          <br />
          The students also created their Safe circle in which they drew and
          wrote about their trusted adults.
          <br />
          <br />
          This activity is a crucial step towards creating a nurturing
          environment and reaffirms the Vidyalaya’s commitment to creating a
          safe space for all the learners.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
